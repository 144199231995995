import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Subscription from "./pages/subscriptions";
import Custompackage from "./pages/custompackage";
import Billing from "./pages/billingpage";
import Prjsettings from "./pages/projectsettings";
import Teammemebers from "./pages/teampage";
import LogssPage from "./pages/logspage";
import Checkoutpage from "./pages/checkoutpage";
import Textsms from "./pages/Testsmspage";
import Payasougopage from "./pages/payasyougopage";
import Checkoutpage2 from "./pages/checkoutpage2";
// import { useState } from "react";

import React from "react";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/projectsettings" element={<Prjsettings />} />
				<Route path="/" element={<Dashboard />} />
				<Route path="/subscriptions" element={<Subscription />} />
				<Route path="/custompackage" element={<Custompackage />} />
				<Route path="/billing" element={<Billing />} />
				<Route path="/textsms" element={<Textsms />} />
				<Route path="/payasyougo" element={<Payasougopage />} />
				<Route path="/team" element={<Teammemebers />} />
				<Route path="/logs" element={<LogssPage />} />
				<Route path="/checkout" element={<Checkoutpage />} />
				<Route path="/checkout2" element={<Checkoutpage2 />} />
			</Routes>
		</Router>
	);
}

export default App;
