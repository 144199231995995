import React, { createContext, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
	const [darkmode, setDarkMode] = useState(false);

	const toggleMode = () => {
		setDarkMode((prevMode) => !prevMode);
		document.body.style.backgroundColor = darkmode ? "#F0F0F0" : "#383854";
	};

	return (
		<AppContext.Provider value={{ darkmode, toggleMode }}>
			{children}
		</AppContext.Provider>
	);
};

export { AppContext, AppProvider };
