import Slider from "../slider/slider";
import { Link } from "react-router-dom";
import "./custom.css";
import { useState } from "react";

export default function Custom(props) {
	const [value, setvalue] = useState(1000);
	const handleChange = (event) => {
		setvalue(event.target.value);
	};
	return (
		<>
			<div className="custom-container">
				<div className="bothsection">
					<div className="topsection">
						<div className="topsectionleft">
							<div className="leftimage">
								<Link to="/subscriptions">
									<div className="imgbtn">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="11"
											height="19"
											viewBox="0 0 11 19"
											fill="none">
											<path
												d="M9.48525 1L1 9.515L9.48525 18.03"
												stroke={props.mode ? "white" : "black"}
												strokeWidth="1.5"
												stroke-miterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</Link>
							</div>
							<div
								className="lefttext"
								style={{ color: props.mode ? "white" : "" }}>
								Custom Package
							</div>
						</div>
						<div className="topsectionright">
							<div
								className="rightrectangle"
								style={{ background: props.mode ? "rgb(46, 46, 72)" : "" }}>
								<div
									className="rtext"
									style={{ color: props.mode ? "white" : "" }}>
									Total Calculated Cost
								</div>

								<div
									className="rprice"
									style={{ color: props.mode ? "white" : "" }}>
									20,000 PKR
								</div>
							</div>
						</div>
					</div>
					<div className="bottomsection">
						<div className="bottomtop">
							<div
								className="btext"
								style={{ color: props.mode ? "white" : "" }}>
								Time Period
							</div>
							<div
								className="btrectangle"
								style={{ background: props.mode ? "rgb(46, 46, 72)" : "" }}>
								<div className="subtrectangle">
									<div
										className="tabs"
										style={{ background: props.mode ? "rgb(56, 56, 84)" : "" }}>
										<input type="radio" id="radio-1" name="tabs" />
										<label
											className="tab"
											for="radio-1"
											style={{ color: props.mode ? "white" : "" }}>
											30 Days
										</label>
										<input type="radio" id="radio-2" name="tabs" />
										<label
											className="tab"
											for="radio-2"
											style={{ color: props.mode ? "white" : "" }}>
											60 Days
										</label>
										<input type="radio" id="radio-3" name="tabs" />
										<label
											className="tab"
											for="radio-3"
											style={{ color: props.mode ? "white" : "" }}>
											90 Days
										</label>

										<span className="glider"></span>
									</div>
								</div>
							</div>
						</div>
						<div className="bottomdown">
							<div
								className="btext2"
								style={{ color: props.mode ? "white" : "" }}>
								Number of SMS
							</div>
							<div
								className="btrectangle2"
								style={{ background: props.mode ? "rgb(46, 46, 72)" : "" }}>
								<div className="slidertxt">
									<h2
										className="sldtxt"
										style={{ color: props.mode ? "white" : "" }}>
										RS.{value}
									</h2>
									<div className="slideritself">
										<Slider
											value={value}
											handleChange={handleChange}
											onChange={handleChange}
											min={10000}
											max={100000}
											step={50}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Link to="/checkout2">
						<div className="custombtndiv">
							<button className="custombtn">Checkout</button>
						</div>
					</Link>
				</div>
			</div>
		</>
	);
}
