import "./navbar.css";
import { useState } from "react";

export default function Navbar(props) {
	const [isOpen, setIsOpen] = useState(false);

	const toggleProfileOptions = () => {
		if (isOpen === false) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
		console.log(isOpen);
	};
	return (
		<div className="topnav" style={{ color: props.mode ? "white" : "" }}>
			<div className="nav-mainhead-container">
				<h2 className="nav-mainhead">
					<span style={{ cursor: "pointer" }}>{props.span }</span>{" "}
					{props.username}
				</h2>
				<svg
					style={{ fill: props.mode ? "white" : "black" }}
					xmlns="http://www.w3.org/2000/svg"
					width="14"
					height="9"
					viewBox="0 0 14 9"
					fill="none">
					<path
						d="M5.32716 7.88333L0.560496 3.11667C-0.02006 2.53611 -0.149615 1.87183 0.171829 1.12383C0.493274 0.375833 1.06589 0.00122222 1.88966 0H11.3313C12.1563 0 12.7295 0.374611 13.051 1.12383C13.3724 1.87306 13.2423 2.53733 12.6605 3.11667L7.89383 7.88333C7.71049 8.06667 7.51188 8.20417 7.298 8.29583C7.08411 8.3875 6.85494 8.43333 6.6105 8.43333C6.36605 8.43333 6.13688 8.3875 5.923 8.29583C5.70911 8.20417 5.5105 8.06667 5.32716 7.88333Z"
						fill=""
					/>
				</svg>
			</div>

			<div className="right-nav">
				<div
					className="notifications"
					style={{ backgroundColor: props.mode ? "#2E2E48" : "" }}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="25"
						height="25"
						viewBox="0 0 25 25"
						fill="none">
						<path
							d="M18.1179 8.12891C18.1179 6.53761 17.4858 5.01148 16.3606 3.88627C15.2353 2.76105 13.7092 2.12891 12.1179 2.12891C10.5266 2.12891 9.0005 2.76105 7.87528 3.88627C6.75006 5.01148 6.11792 6.53761 6.11792 8.12891C6.11792 15.1289 3.11792 17.1289 3.11792 17.1289H21.1179C21.1179 17.1289 18.1179 15.1289 18.1179 8.12891Z"
							stroke={props.mode ? "white" : "#A7A8AB"}
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M13.8479 21.1289C13.6721 21.432 13.4198 21.6836 13.1162 21.8584C12.8125 22.0333 12.4683 22.1254 12.1179 22.1254C11.7676 22.1254 11.4233 22.0333 11.1197 21.8584C10.8161 21.6836 10.5637 21.432 10.3879 21.1289"
							stroke={props.mode ? "white" : "#A7A8AB"}
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="profile" onClick={toggleProfileOptions}>
					<img className="profile-pic" src="./images/profile_icon.png" alt="" />
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="17"
						height="16"
						viewBox="0 0 17 16"
						fill="none">
						<path
							d="M4.71582 6L8.71582 10L12.7158 6"
							stroke={props.mode ? "white" : "#A7A8AB"}
							// stroke="#A7A8AB"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div
					className="profile-options"
					style={{
						display: isOpen ? "flex" : "none",
						backgroundColor: props.mode ? "rgb(46, 46, 72)" : "white",
					}}>
					<div className="opt1">
						<p className="opt-sub-head">Logout</p>
					</div>
					<div className="opt2">
						<p className="opt-sub-head">Change Profile</p>
					</div>
				</div>
			</div>
		</div>
	);
}
