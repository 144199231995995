import "./Subcards.css";
import { Link } from "react-router-dom";
import Card from "../scard/scard";
export default function Subcards(props) {
	const Datacards = [
		{
			cardtitle: "Starter",
			dis1: "4,000 SMS",
			dis2: "30 Days time period",
			dis3: "Transactional/Promotional",
			dis4: "2.8 PKR/message",
			price: "9,400 PKR",
			cardbutton: "Current Package",
			size: "35px",
			backgroundColor: "#4169E1",
			color: "white",
		},
		{
			cardtitle: "Basic",
			dis1: "10,000 SMS",
			dis2: "30 Days time period",
			dis3: "Transactional/Promotional",
			dis4: "2.8 PKR/message",
			price: "23,000 PKR",
			cardbutton: "Upgrade Package",
			size: "35px",
			btncolor: "#4169E1",
			backgroundColor: props.mode ? "#2E2E48" : "white",
			color: props.mode ? "white" : "black",
		},
		{
			cardtitle: "Standard",
			dis1: "30,000 SMS",
			dis2: "45 Days time period",
			dis3: "Transactional/Promotional",
			dis4: "2.8 PKR/message",
			price: "70,000 PKR",
			cardbutton: "Upgrade Package",
			size: "35px",
			btncolor: "#4169E1",
			backgroundColor: props.mode ? "#2E2E48" : "white",
			color: props.mode ? "white" : "black",
		},
		{
			cardtitle: "Enterprise",
			dis1: "500,000 SMS",
			dis2: "90 Days time period",
			dis3: "Transactional/Promotional",
			dis4: "2.8 PKR/message",
			price: "1,150,000 PKR",
			cardbutton: "Upgrade Package",
			size: "30px",
			btncolor: "#4169E1",
			backgroundColor: props.mode ? "#2E2E48" : "white",
			color: props.mode ? "white" : "black",
		},
	];
	return (
		<>
			<div className="outermost-container">
				<div className="outer-container">
					<div className="top">
						<div
							className="uheading"
							style={{ color: props.mode ? "white" : "" }}>
							Upgrade Bundle
						</div>
						<div className="btns1">
							<Link to="/payasyougo">
								<button className="mpurchasebtnm">Pay as you GO</button>
							</Link>
							<Link to="/custompackage">
								<button className="mpurchasebtnm">Custom Package</button>
							</Link>
						</div>
					</div>
					<div className="souter-container">
						{Datacards?.map((data, index) => {
							return (
								<>
									<div
										style={{
											margin: "0 0 50px 0",
										}}
										key={index}>
										<Card
											size={data.size}
											btncolor={data.btncolor}
											cardtitle={data.cardtitle}
											dis1={data.dis1}
											dis2={data.dis2}
											dis3={data.dis3}
											dis4={data.dis4}
											price={data.price}
											cardbutton={data.cardbutton}
											backgroundColor={data.backgroundColor}
											color={data.color}
										/>
									</div>
								</>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
}
