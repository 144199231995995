import "./scard.css";

const Card = (props) => {
	return (
		<>
			<div className="mcard">
				<div
					className="all-content"
					style={{ backgroundColor: props.backgroundColor }}>
					<div className="mcard-content">
						<div className="mcard-td">
							<h3 className="mcard-title" style={{ color: props.color }}>
								{props.cardtitle}
							</h3>
							<div className="mcard-description">
								<ul className="list-description" style={{ color: props.color }}>
									<li>{props.dis1}</li>
									<li>{props.dis2}</li>
									<li>{props.dis3}</li>
									<li>{props.dis4}</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="price-section">
						<div
							className="price"
							style={{ fontSize: props.size, color: props.color }}>
							{props.price}
						</div>
						<div className="mpricebtn">
							<button
								className="mpurchasebtnmm"
								style={{ backgroundColor: props.btncolor }}>
								{props.cardbutton}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Card;
