import React, { useState } from "react";
import "./team.css";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUser,
	faPlus,
	faCheck,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import validator from "validator";
import { Button } from "antd";

const initialData = [
	{
		id: 1,
		title: "muhammad@gmail.com",
		year: "Owner",
	},
	{
		id: 2,
		title: "uzair@gmail.com",
		year: "Editor",
	},
	{
		id: 3,
		title: "muhammad@gmail.com",
		year: "Owner",
	},
	{
		id: 4,
		title: "ibrahim@gmail.com",
		year: "Owner",
	},
	{
		id: 5,
		title: "muhammad@gmail.com",
		year: "Owner",
	},
	{
		id: 6,
		title: "muhammad@gmail.com",
		year: "Owner",
	},
	{
		id: 7,
		title: "muhammad@gmail.com",
		year: "Owner",
	},
	{
		id: 8,
		title: "uzair@gmail.com",
		year: "Owner",
	},
	{
		id: 9,
		title: "muhammad@gmail.com",
		year: "Owner",
	},
	{
		id: 10,
		title: "uzair@gmail.com",
		year: "Owner",
	},
	{
		id: 11,
		title: "muhammad@gmail.com",
		year: "Owner",
	},
	{
		id: 12,
		title: "muhammad@gmail.com",
		year: "Owner",
	},
	{
		id: 13,
		title: "ibrahim@gmail.com",
		year: "Owner",
	},
	{
		id: 14,
		title: "muhammad@gmail.com",
		year: "Owner",
	},
	{
		id: 15,
		title: "ibrahim@gmail.com",
		year: "Owner",
	},
];

const roleOptions = ["Editor", "Owner"];

export default function Team(props) {
	const [selectedRow, setSelectedRow] = useState(null);
	const [data, setData] = useState(initialData);
	const [selectedRole, setSelectedRole] = useState("");
	const [showAddMemberPopup, setShowAddMemberPopup] = useState(false);
	const [newMemberEmail, setNewMemberEmail] = useState("");
	const [newMemberRole, setNewMemberRole] = useState("");
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [selectedRowIndex, setSelectedRowIndex] = useState(null);
	const [newMemberEmailError, setNewMemberEmailError] = useState(false); // Define error state
	const [newMemberRoleError, setNewMemberRoleError] = useState(false);

	const handleRowClick = (row) => {
		setSelectedRow(row);
		setSelectedRole(row.year);
	};

	const handleClosePopUp = () => {
		setSelectedRow(null);
		setSelectedRole("");
	};

	const handleSaveChanges = () => {
		if (selectedRow !== null) {
			const newData = data.map((row) =>
				row.id === selectedRow.id ? { ...row, year: selectedRole } : row
			);
			setData(newData);
			handleClosePopUp();
		}
	};

	const handleAddMemberClick = () => {
		setShowAddMemberPopup(true);
	};
	const handleAddMemberClose = () => {
		setNewMemberEmail("");
		setNewMemberRole("");
		setNewMemberEmailError(false);
		setNewMemberRoleError(false);
		setShowAddMemberPopup(false); // Update the state to close the popup
	};

	const handleDeleteRow = (rowId) => {
		setSelectedRowIndex(rowId);
		setShowDeleteConfirmation(true);
	};

	const handleDeleteMember = () => {
		const newData = data.filter((item) => item.id !== selectedRowIndex);
		setData(newData);
		setSelectedRowIndex(null);
		setShowDeleteConfirmation(false);
	};

	const handleAddMember = () => {
		if (newMemberEmail && newMemberRole && validator.isEmail(newMemberEmail)) {
			// ... (rest of the validation checks)
			const newMember = {
				id: data.length + 1,
				title: newMemberEmail,
				year: newMemberRole,
			};
			setData([...data, newMember]); // Add the new member to the data state
			handleAddMemberClose();
		} else {
			if (!newMemberEmail || !validator.isEmail(newMemberEmail)) {
				setNewMemberEmailError(true);
			} else {
				setNewMemberEmailError(false);
			}

			if (!newMemberRole) {
				setNewMemberRoleError(true);
			} else {
				setNewMemberRoleError(false);
			}
		}
	};

	const columns = [
		{
			name: "Members",
			cell: (row) => (
				<div className="profile-icon-container">
					<div className="profile-icon">
						<FontAwesomeIcon icon={faUser} />
					</div>
					<div className="profile-icon-text">{row.title}</div>
				</div>
			),
		},
		{
			name: "Roles",
			selector: (row) => row.year,
			cell: (row) => (
				<div className="role-cell" onClick={() => handleRowClick(row)}>
					{row.year}
				</div>
			),
		},
		{
			name: "",
			cell: (row) => (
				<div className="trash-icon-container">
					<svg
						onClick={() => handleDeleteRow(row.id)}
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="20"
						viewBox="0 0 18 20"
						fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M6.1875 3.75V1.875H11.8125V3.75H6.1875ZM4.3125 3.75V1.25C4.3125 0.918479 4.4442 0.600537 4.67862 0.366117C4.91304 0.131696 5.23098 0 5.5625 0L12.4375 0C12.769 0 13.087 0.131696 13.3214 0.366117C13.5558 0.600537 13.6875 0.918479 13.6875 1.25V3.75H16.8125C17.0611 3.75 17.2996 3.84877 17.4754 4.02459C17.6512 4.2004 17.75 4.43886 17.75 4.6875C17.75 4.93614 17.6512 5.1746 17.4754 5.35041C17.2996 5.52623 17.0611 5.625 16.8125 5.625H16.3563L15.4275 17.6912C15.3793 18.3193 15.0958 18.906 14.6337 19.3341C14.1716 19.7621 13.5649 20 12.935 20H5.065C4.4351 20 3.82842 19.7621 3.36631 19.3341C2.90419 18.906 2.62069 18.3193 2.5725 17.6912L1.64375 5.625H1.1875C0.93886 5.625 0.700403 5.52623 0.524587 5.35041C0.348772 5.1746 0.25 4.93614 0.25 4.6875C0.25 4.43886 0.348772 4.2004 0.524587 4.02459C0.700403 3.84877 0.93886 3.75 1.1875 3.75H4.3125ZM3.525 5.625H14.475L13.5575 17.5475C13.5455 17.7045 13.4748 17.8511 13.3594 17.9582C13.244 18.0653 13.0924 18.1248 12.935 18.125H5.065C4.90758 18.1248 4.75603 18.0653 4.64062 17.9582C4.52522 17.8511 4.45446 17.7045 4.4425 17.5475L3.525 5.625Z"
							fill="black"
						/>
					</svg>
				</div>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	return (
		<>
			<div className="touter-container">
				<div
					className="theading"
					style={{
						color: props.mode ? "white" : "black",
					}}>
					Team
				</div>
				<button className="add-member-button" onClick={handleAddMemberClick}>
					Add Member
				</button>
				<hr className="hr2" />
				<DataTable
					// fixedHeader
					// fixedHeaderScrollHeight="650px"
					columns={columns}
					data={data}
					highlightOnHover
					pointerOnHover
					onRowClicked={handleRowClick}
				/>
			</div>

			{selectedRow !== null && (
				<div className="popup-container">
					<div className="popup-content">
						{/* <div>
							Selected Member:{" "}
							{data.find((row) => row.id === selectedRow.id)?.title}
						</div> */}
						<div>
							<div className="rolediv">
								<label htmlFor="roleSelect">Role</label>
								<select
									id="roleSelect"
									value={selectedRole}
									onChange={(e) => setSelectedRole(e.target.value)}>
									<option value="">Select Role</option>
									{roleOptions.map((role) => (
										<option key={role} value={role}>
											{role}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="button-container">
							<button onClick={handleSaveChanges}>Save</button>
							<button
								onClick={handleClosePopUp}
								style={{
									border: "2px solid black",
									backgroundColor: "white",
									color: "black",
								}}>
								Close
							</button>
						</div>
					</div>
				</div>
			)}

			{showAddMemberPopup && (
				<div className="popup-container">
					<div className="popup-content">
						<div>Add New Member</div>
						<div className="emoildiv">
							<label htmlFor="newMemberEmail">Email</label>
							<input
								className={`memberemaiol ${newMemberEmailError ? "error" : ""}`} // Apply error class if there's an error
								type="text"
								id="newMemberEmail"
								value={newMemberEmail}
								onChange={(e) => {
									setNewMemberEmail(e.target.value);
									setNewMemberEmailError(false); // Reset error state on input change
								}}
							/>
						</div>
						<div className="rolediv">
							<label htmlFor="newMemberRole">Role</label>
							<select
								className={`${newMemberRoleError ? "error" : ""}`}
								id="newMemberRole"
								value={newMemberRole}
								onChange={(e) => {
									setNewMemberRole(e.target.value);
									setNewMemberRoleError(false); // Reset error state on input change
								}}>
								<option value="">Select Role</option>
								{roleOptions.map((role) => (
									<option key={role} value={role}>
										{role}
									</option>
								))}
							</select>
						</div>
						<div className="button-container">
							<button
								onClick={handleAddMember}
								style={{
									backgroundColor: "",
									color: "white",
								}}>
								Add
							</button>
							<button
								onClick={handleAddMemberClose}
								style={{
									border: "2px solid black",
									backgroundColor: "white",
									color: "black",
								}}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}

			{showDeleteConfirmation && (
				<>
					<div className="popup-container">
						<div className="confirmation-dialog">
							<div className="confirmation-content">
								<div className="confirmation-message">
									Are you sure you want to delete this member?
								</div>
								<div className="confirmation-icons">
									<Button
										// icon={faTimes}
										className="confirmation-icon confirmation-icon-no"
										onClick={handleDeleteMember}>
										YES
									</Button>
									<Button
										// icon={faCheck}
										className="confirmation-icon confirmation-icon-yes"
										onClick={() => setShowDeleteConfirmation(false)}>
										NO
									</Button>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
