import React from "react";
import "./slider.css"

function Slider({value, handleChange,min,max,step}){

    return(
        <div>
            <input className="mslider" type="range" defaultValue={value} onChange={handleChange} min={min} max={max} step={step} />
        </div>
    )
}
export default Slider