import "../main.css";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { useState } from "react";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../Theme";
// import Subcards from "../components/Subcards/Subcards";
import Sidebar from "../components/sidebar/sidebar";
import Navbar from "../components/navbar/navbar";
import Billingnew from "../components/billing/billing";
export default function Dashboard() {
	const { darkmode, toggleMode } = useContext(AppContext);

	// const [darkmode, setdarkMode] = useState(false);

	// const toggleMode = () => {
	// 	if (darkmode === false) {
	// 		setdarkMode(true);
	// 		document.body.style.backgroundColor = "#383854";
	// 	} else {
	// 		setdarkMode(false);
	// 		document.body.style.backgroundColor = "#F2F2FD";
	// 	}
	// };
	return (
		<>
			<div className="sidebar-component">
				<Sidebar mode={darkmode} toggleMode={toggleMode} />
			</div>
			<div className="navbar-component">
				<Navbar mode={darkmode} username="Nexcare" />
			</div>
			<Billingnew mode={darkmode} toggleMode={toggleMode} />
		</>
	);
}
