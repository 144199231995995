import "./testsms.css";
import { PrimarySelect, TextareaLarge } from "../inputs/inputs.jsx";

export default function Testsms(props) {
	const SMSDATA = {
		smstype: ["Promotional", "Transactional"],
		senderid: ["NexSMS"],
		smsencoding: [
			"Text (Use only English)",
			"Unicode (Use for all languages)",
			"Flash SMS (Use only English)",
			"Unicode Flash SMS (Use for all languages)",
		],
		smstemplate: ["Select Template"],
	};
	return (
		<>
			<div className="testsmsupper">
				<h3
					style={{
						marginBottom: "0rem",
						// marginLeft: "60px",
						fontSize: "30px",
						fontWeight: "bold",
						textAlign: "center",
						color: props.mode ? "white" : "black",
					}}>
					Send Test SMS
				</h3>

				<div className="selections">
					<PrimarySelect label="SMS Type" options={SMSDATA.smstype} />
					<PrimarySelect label="Select Sender ID" options={SMSDATA.senderid} />

					<PrimarySelect
						label="Select SMS Encoding"
						options={SMSDATA.smsencoding}
					/>
					<PrimarySelect
						label="Select Template"
						options={SMSDATA.smstemplate}
					/>
				</div>
			</div>
			<div className="testsmslower">
				<div className="t1">
					<TextareaLarge label="Enter Text Message" />
					{/* <div className="c1">
						<div className="cb">
							<input type="checkbox" />
							<p
								style={{
									marginBottom: "0rem",
									fontSize: "13px",
									fontFamily: "Space Grotesk",
									fontWeight: "900",
								}}>
								Enable RTL Format
							</p>
						</div>
						<div className="in">
							<p className="ref">Encoding: GSM_7BIT</p>
							<p className="ref">Length: 0</p>
							<p className="ref">Messages: 0</p>
							<p className="ref">Per Message: 160</p>
							<p className="ref">Remaining: 160</p>
						</div>
					</div> */}
				</div>
				<div className="t2">
					<TextareaLarge label="Enter PhoneNumber" />
					{/* <div className="c2">
						<input
							type="button"
							style={{ fontSize: "19px" }}
							value="submit"
							className="mpurchasebtnm"
						/>
					</div> */}
				</div>
			</div>
			<div className="dbz">
				<div className="end">
					<div className="c1">
						<div className="cb">
							<input type="checkbox" />
							<p
								style={{
									marginBottom: "0rem",
									fontSize: "13px",
									fontFamily: "Space Grotesk",
									fontWeight: "300",
									color: props.mode ? "white" : "black",
								}}>
								Enable RTL Format
							</p>
						</div>
						<div
							className="in"
							style={{ color: props.mode ? "white" : "black" }}>
							<p className="ref">Encoding: GSM_7BIT</p>
							<p className="ref">Length: 0</p>
							<p className="ref">Messages: 0</p>
							<p className="ref">Per Message: 160</p>
							<p className="ref">Remaining: 160</p>
						</div>
					</div>
					<div className="c2">
						<input
							type="button"
							style={{ fontSize: "19px" }}
							value="submit"
							className="mpurchasebtnm"
						/>
					</div>
				</div>
			</div>
		</>
	);
}
