import React, { useState } from "react";
import "./editabletext.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
const Editabletext = (props) => {
	const [isEditing, setIsEditing] = useState(false);
	const [text, setText] = useState(props.initialText);

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleSaveClick = () => {
		setIsEditing(false);
		// You can handle the save action here if needed, like sending the edited text to a server or a parent component.
	};

	const handleChange = (event) => {
		setText(event.target.value);
	};

	return (
		<div className="editable-text-box">
			{isEditing ? (
				<div className="edit-mode-container">
					<input
						type="text"
						value={text}
						onChange={handleChange}
						className="edit-text-box"
					/>
					<button onClick={handleSaveClick} className="save-button">
						<FontAwesomeIcon icon={faSave} />
					</button>
				</div>
			) : (
				<div className="non-editable-text" onClick={handleEditClick}>
					{text}
					<button className="edit-button">
						<FontAwesomeIcon icon={faEdit} />
					</button>
				</div>
			)}
		</div>
	);
};

export default Editabletext;
