import "./checkout2.css";
import { Link } from "react-router-dom";
import React from "react";
import { PrimarySelect, PrimaryInput } from "../inputs/inputs";
import { Checkbox } from "antd";
export default function Checkout2(props) {
	const select = {
		options: ["Visa", "Credit", "Debit"],
	};
	return (
		<>
			{/* style={{ color: props.mode ? "white" : "" }} */}
			<div id="upper">
				<Link to="/custompackage">
					<div className="back-img">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="11"
							height="20"
							viewBox="0 0 11 20"
							fill="none">
							<path
								d="M9.48525 1.48486L1 9.99986L9.48525 18.5149"
								stroke={props.mode ? "white" : "black"}
								stroke-width="1.5"
								stroke-miterlimit="10"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
				</Link>
				<h1 className="pg" style={{ color: props.mode ? "white" : "" }}>
					Checkout
				</h1>
			</div>
			<div id="box2" style={{ background: props.mode ? "#2E2E48" : "" }}>
				<div className="left">
					<PrimarySelect options={select.options} label="Payment Type" />
					<PrimaryInput label="Credit Card Number" />
					<PrimaryInput label="Full Name" />
					<div className="fill1">
						<PrimaryInput
							id="inp"
							label="Expiry Date"
							placeholder="06/02/2023"
						/>
						<PrimaryInput
							id="inp"
							label="CVS"
							type="password"
							placeholder="*****"
						/>
					</div>
					<div className="fill2">
						<p
							style={{
								marginBottom: "0rem",
								fontSize: "13px",
								fontWeight: "700",
								color: props.mode ? "white" : "",
							}}>
							Package Start Date:
						</p>
						<PrimaryInput id="inp" placeholder="17/09/2023" />
					</div>
					<div className="fill3">
						<Checkbox checked />
						<p
							style={{
								marginBottom: "0rem",
								color: props.mode ? "white" : "",
							}}>
							Auto Charge
						</p>
					</div>
				</div>
				<div className="right">
					<h2
						style={{ marginBottom: "0rem", color: props.mode ? "white" : "" }}>
						Package
					</h2>
					<div
						className="in_box2"
						style={{ border: props.mode ? "2px solid white" : "" }}>
						<h3
							style={{
								marginBottom: "0rem",
								textAlign: "center",
								fontSize: "20px",
								fontWeight: "800",
								color: props.mode ? "white" : "",
							}}>
							NexCare Custom Package
						</h3>
						<ul>
							<li
								style={{ color: props.mode ? "white" : "", fontSize: "18px" }}>
								4,000 Transactional/
								<br />
								Promotional SMS
							</li>
							<li
								style={{ color: props.mode ? "white" : "", fontSize: "18px" }}>
								{" "}
								30 Days Time Period
							</li>
						</ul>
						<div className="filler1">
							<p
								style={{
									marginBottom: "0rem",
									fontWeight: "600",
									color: props.mode ? "white" : "",
								}}>
								End Date: 13/03/2023
							</p>
							<p
								style={{
									marginBottom: "0rem",
									fontWeight: "600",
									color: props.mode ? "white" : "",
								}}>
								Start Date: 12/02/2023
							</p>
						</div>
						<h3
							style={{
								marginBottom: "0rem",
								textAlign: "center",
								fontSize: "20px",
								fontWeight: "800",
								color: props.mode ? "white" : "",
							}}>
							Price: 9,400
						</h3>
					</div>
					<div className="btns">
						<Link
							to="/payasyougo"
							style={{
								textDecoration: "lined",
								fontSize: "17px",
								color: "#727272",
								fontWeight: "600",
							}}>
							Cancel
						</Link>
						<Link to="/">
							<button className="btc" style={{ fontSize: "16px" }}>
								Pay Now
							</button>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}
