// Dashboard.js
import { useContext } from "react";
import { AppContext } from "../Theme";
import Sidebar from "../components/sidebar/sidebar";
import Navbar from "../components/navbar/navbar";
import Datacards from "../components/datacards/datacards";
import Datacards2 from "../components/datacards/datacards2";
import "../main.css";

export default function Dashboard() {
	const { darkmode, toggleMode } = useContext(AppContext);

	return (
		<>
			<div className="sidebar-component">
				<Sidebar mode={darkmode} toggleMode={toggleMode} />
			</div>
			<div className="navbar-component">
				<Navbar mode={darkmode} username="NexCare" />
			</div>
			<div className="card-component1">
				<Datacards mode={darkmode} />
			</div>
			<div className="card-component2">
				<Datacards2 mode={darkmode} />
			</div>
		</>
	);
}
