import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import DataTable from "react-data-table-component";
import "./billing.css"; // Make sure to adjust the import path for your CSS file

export default function Billingnew(props) {
	useEffect(() => {
		const handleWindowResize = () => {
			setShowHorizontalScroll(window.innerWidth < 1750);
		};

		// Initial check on component mount
		handleWindowResize();

		// Add event listener for window resize
		window.addEventListener("resize", handleWindowResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);
	const data2 = [
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},

		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
		{
			column1: "Invoice #007-Dec 2023",
			column2: "4/09/2023",
			column3: "24,500 PKR",
			column4: "+9232459234",
			column5: "+927993829",
		},
	]; // Your data array

	const columns2 = [
		{
			name: "Invoice",
			selector: "column1",
			cell: (row) => (
				<div className="invoice-cell">
					<svg
						className="svgpdf"
						xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none">
						<g clip-path="url(#clip0_1134_2145)">
							<path
								d="M3.75 27.1875H5.625V30H3.75C1.68164 30 0 28.3184 0 26.25V3.75C0 1.68164 1.68164 0 3.75 0H13.4473C14.4434 0 15.3984 0.392578 16.1016 1.0957L21.4043 6.39844C22.1074 7.10156 22.5 8.05664 22.5 9.05273V16.875H19.6875V9.375H15C13.9629 9.375 13.125 8.53711 13.125 7.5V2.8125H3.75C3.23438 2.8125 2.8125 3.23438 2.8125 3.75V26.25C2.8125 26.7656 3.23438 27.1875 3.75 27.1875ZM10.3125 20.625H12.1875C13.998 20.625 15.4688 22.0957 15.4688 23.9062C15.4688 25.7168 13.998 27.1875 12.1875 27.1875H11.25V29.0625C11.25 29.5781 10.8281 30 10.3125 30C9.79688 30 9.375 29.5781 9.375 29.0625V21.5625C9.375 21.0469 9.79688 20.625 10.3125 20.625ZM12.1875 25.3125C12.9668 25.3125 13.5938 24.6855 13.5938 23.9062C13.5938 23.127 12.9668 22.5 12.1875 22.5H11.25V25.3125H12.1875ZM17.8125 20.625H19.6875C21.2402 20.625 22.5 21.8848 22.5 23.4375V27.1875C22.5 28.7402 21.2402 30 19.6875 30H17.8125C17.2969 30 16.875 29.5781 16.875 29.0625V21.5625C16.875 21.0469 17.2969 20.625 17.8125 20.625ZM19.6875 28.125C20.2031 28.125 20.625 27.7031 20.625 27.1875V23.4375C20.625 22.9219 20.2031 22.5 19.6875 22.5H18.75V28.125H19.6875ZM24.375 21.5625C24.375 21.0469 24.7969 20.625 25.3125 20.625H28.125C28.6406 20.625 29.0625 21.0469 29.0625 21.5625C29.0625 22.0781 28.6406 22.5 28.125 22.5H26.25V24.375H28.125C28.6406 24.375 29.0625 24.7969 29.0625 25.3125C29.0625 25.8281 28.6406 26.25 28.125 26.25H26.25V29.0625C26.25 29.5781 25.8281 30 25.3125 30C24.7969 30 24.375 29.5781 24.375 29.0625V21.5625Z"
								fill="black"
							/>
						</g>
						<defs>
							<clipPath id="clip0_1134_2145">
								<rect width="30" height="30" fill="white" />
							</clipPath>
						</defs>
					</svg>

					<span className="invoice-text">{row.column1}</span>
				</div>
			),
			sortable: true,
		},
		{
			name: "Billing Date",
			selector: "column2",
			sortable: true,
		},
		{
			name: "Amount",
			selector: "column3",
			sortable: true,
		},
		{
			name: "",
			selector: "column4",
			cell: (row) => (
				<div className="download-cell">
					<FontAwesomeIcon icon={faDownload} className="download-icon" />
					<span className="download-text">Download</span>
					<FontAwesomeIcon icon={faEllipsisV} className="kebab-icon" />
				</div>
			),
			ignoreRowClick: true,
		},
	];

	const [showHorizontalScroll, setShowHorizontalScroll] = useState(false);

	useEffect(() => {
		const handleWindowResize = () => {
			setShowHorizontalScroll(window.innerWidth < 1750);
		};

		// Initial check on component mount
		handleWindowResize();

		// Add event listener for window resize
		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	return (
		<>
			<div className="loutermost-container2">
				<div className="louter-container2">
					<div
						className="lheading2"
						style={{
							color: props.mode ? "white" : "black",
						}}>
						Billing
					</div>
					<div
						className={`logstablecontainer2 ${
							showHorizontalScroll ? "show-scroll" : ""
						}`}>
						<DataTable
							// fixedHeader
							// fixedHeaderScrollHeight="600px"
							highlightOnHover
							// pointerOnHover
							columns={columns2}
							data={data2}
							responsive={true}
							overflowY={"auto"}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
