import "./datacards.css";
import Chart from "react-apexcharts";
import React from "react";
import { Link } from "react-router-dom";
export default function Datacards2(props) {
	const bar = {
		options: {
			colors: ["#4169E1", "#4169e166"],
			chart: {
				id: "basic-bar", // Update the id if needed
				height: 330,
				width: 630,
				type: "bar", // Set the chart type to "bar"
			},
			legend: {
				show: true,
				labels: {
					colors: props.mode ? "white" : "",
					fontFamily: "inter",
				},
			},
			xaxis: {
				categories: [
					"Jan",
					"Feb",
					"Mar",
					"Apr",
					"May",
					"Jun",
					"Jul",
					"Aug",
					"Sep",
					"Oct",
					"Nov",
					"Dec",
				],
				labels: {
					show: true,
					style: {
						colors: props.mode ? "white" : "",
					},
				},
				axisBorder: {
					show: true,
				},
				gridLines: {
					show: true,
				},
			},
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: props.mode ? "white" : "",
					},
				},
				axisBorder: {
					show: true,
				},
			},
			plotOptions: {
				bar: {
					columnWidth: "40%",
					// borderRadius: 5,
				},
			},
			dataLabels: {
				enabled: true,
			},
			grid: {
				show: false,
				padding: {
					top: 0,
					bottom: 0,
				},
			},
		},
		series: [
			{
				name: "Used",
				data: [400, 500, 100, 400, 200, 500, 400, 600, 800, 900, 700, 900],
			},
			{
				name: "Wasted",
				data: [300, 150, 200, 100, 100, 300, 500, 400, 500, 700, 500, 800],
			},
		],
	};

	const sub_donut1 = {
		options: {
			labels: ["Remaining", "Used"],
			colors: ["#4169E1", "#4169e11f"],
			plotOptions: {
				pie: {
					donut: {
						size: "70px",
						labels: {
							show: true,
							name: {
								show: false,
								fontSize: "10px",
								fontFamily: "Arial, sans-serif",
								color: "#888",
								fontWeight: "900",
								offsetY: 50,
								formatter: function (val) {
									return val;
								},
							},
							value: {
								show: true,
								fontSize: "15px",
								fontFamily: "Arial, sans-serif",
								fontWeight: 600,
								color: props.mode ? "white" : "",
								offsetY: 6,
								formatter: function () {
									return "2,250";
								},
							},
							total: {
								show: true,
								fontSize: "7px",
								label: "",
								color: "#888",
								formatter: function () {
									return "2,250";
								},
							},
						},
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
		},
		series: [2, 1],
	};
	const sub_donut2 = {
		options: {
			labels: ["Remaining", "Used"],
			colors: ["#FF4F79", "#ff4f793d"],
			plotOptions: {
				pie: {
					donut: {
						stroke: {
							color: "black", // Set the stroke color to transparent
							show: true,
						},
						size: "70px",
						labels: {
							show: true,
							name: {
								show: false,
								fontSize: "15px",
								fontFamily: "Arial, sans-serif",
								color: "#888",
								fontWeight: "900",
								offsetY: 50,
								formatter: function (val) {
									return val;
								},
							},
							value: {
								show: true,
								fontSize: "15px",
								fontFamily: "Arial, sans-serif",
								fontWeight: 600,
								color: props.mode ? "white" : "",
								offsetY: 6,
								formatter: function () {
									return "1,142";
								},
							},
							total: {
								show: true,
								label: "",
								color: "#888",
								formatter: function () {
									return "1,142";
								},
							},
						},
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
		},
		series: [0.5, 1],
	};

	return (
		<div className="comp3">
			<div
				className="data_card_1"
				style={{ backgroundColor: props.mode ? "#2E2E48" : "" }}>
				<div className="_card1_heading">
					<h2 style={{ color: props.mode ? "white" : "" }}>Chart Text</h2>
				</div>

				<Chart
					options={bar.options}
					series={bar.series}
					type="bar"
					width={bar.options.chart.width}
					height={bar.options.chart.height}
				/>
			</div>
			<div
				className="data_card_2"
				style={{ backgroundColor: props.mode ? "#2E2E48" : "" }}>
				<div className="subs">
					<h2
						style={{ color: props.mode ? "white" : "black" }}
						className="titel">
						Active Subscriptions
					</h2>
					<div className="uz-img">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="30"
							height="39"
							viewBox="0 0 25 24"
							fill={props.mode ? "white" : "black"}>
							<path
								d="M12.5 13C13.0523 13 13.5 12.5523 13.5 12C13.5 11.4477 13.0523 11 12.5 11C11.9477 11 11.5 11.4477 11.5 12C11.5 12.5523 11.9477 13 12.5 13Z"
								stroke="#878997"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M19.5 13C20.0523 13 20.5 12.5523 20.5 12C20.5 11.4477 20.0523 11 19.5 11C18.9477 11 18.5 11.4477 18.5 12C18.5 12.5523 18.9477 13 19.5 13Z"
								stroke="#878997"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M5.5 13C6.05228 13 6.5 12.5523 6.5 12C6.5 11.4477 6.05228 11 5.5 11C4.94772 11 4.5 11.4477 4.5 12C4.5 12.5523 4.94772 13 5.5 13Z"
								stroke="#878997"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
				</div>
				<div className="_cards">
					<div className="_card1">
						<img src="./images/logo-rt 2.png" alt="" srcSet="" />
						<div className="comp-det">
							<h3
								className="company_name"
								style={{ color: props.mode ? "white" : "" }}>
								Rising Tech
							</h3>
							<p className="p-type">Basic Package</p>
						</div>
						<div className="sub1">
							<h5
								className="sub_h"
								style={{ color: props.mode ? "white" : "" }}>
								Last Invoice: 10,500
								<br />
								Status: Due on 22/5/2023
							</h5>
							<Link to="/billing">
								<h4 className="link">Pay Now</h4>
							</Link>
						</div>
						{/* <div className="sub2">
							<h5
								className="sub_h"
								style={{ color: props.mode ? "white" : "" }}>
								Start: 2/6/23
								<br />
								End: 22/9/23
							</h5>
						</div> */}
						<div className="donut1">
							<Chart
								options={sub_donut1.options}
								series={sub_donut1.series}
								type="donut"
								width={140}
								height={140}
							/>
							<p
								className="validate"
								style={{ color: props.mode ? "white" : "" }}>
								till: 22/9/23
							</p>
						</div>
					</div>
					<div className="_card2">
						<img
							className="ml"
							src="./images/logo.7fcbc781 1.png"
							alt=""
							srcSet=""
						/>
						<div className="comp-det">
							<h3
								className="company_name"
								style={{ color: props.mode ? "white" : "" }}>
								NexCare
							</h3>
							<p className="p-type">Starter Package</p>
						</div>
						<div className="sub1">
							<h5
								className="sub_h"
								style={{ color: props.mode ? "white" : "" }}>
								Last Invoice: 10,500
								<br />
								Status: Paid on 22/5/2023
							</h5>
						</div>
						{/* <div className="sub2">
							<h5
								className="sub_h"
								style={{ color: props.mode ? "white" : "" }}>
								Start: 2/6/23
								<br />
								End: 22/9/23
							</h5>
						</div> */}
						<div className="donut2">
							<Chart
								options={sub_donut2.options}
								series={sub_donut2.series}
								type="donut"
								width={140}
								height={140}
							/>
							<p
								className="validate"
								style={{ color: props.mode ? "white" : "" }}>
								till: 22/9/23
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
