import "./logs.css";
import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import DataTable from "react-data-table-component";
import { BarLoader } from "react-spinners";

const { RangePicker } = DatePicker;

export default function Logs(props) {
	const [text1, setText1] = useState("Ticket Number");
	const [text2, setText2] = useState("Status");
	const [isLoading, setIsLoading] = useState(true);

	const handleFocus1 = () => {
		setText1("");
	};

	const handleFocus2 = () => {
		setText2("");
	};

	const handleChange1 = (event) => {
		setText1(event.target.value);
	};

	const handleChange2 = (event) => {
		setText2(event.target.value);
	};

	const data = [
		{
			column1: "9",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Sent",
			column7: "2.8 PKR",
		},
		{
			column1: "8",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Sent",
			column7: "2.8 PKR",
		},
		{
			column1: "7",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Sent",
			column7: "2.8 PKR",
		},
		{
			column1: "6",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Failed",
			column7: "2.8 PKR",
		},
		{
			column1: "5",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Error",
			column7: "2.8 PKR",
		},
		{
			column1: "4",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Error",
			column7: "2.8 PKR",
		},
		{
			column1: "3",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Sent",
			column7: "2.8 PKR",
		},

		{
			column1: "2",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Failed",
			column7: "2.8 PKR",
		},
		{
			column1: "10",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Error",
			column7: "2.8 PKR",
		},
		{
			column1: "11",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Error",
			column7: "2.8 PKR",
		},
		{
			column1: "22",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Failed",
			column7: "2.8 PKR",
		},
		{
			column1: "37",
			column2: "#0494893578",
			column3: "4/11/2023",
			column4: "+9232459234",
			column5: "+927993829",
			column6: "Sent",
			column7: "2.8 PKR",
		},
	];

	const columns = [
		{
			name: "Log ID",
			selector: "column1", // Replace with the actual key from your data
			sortable: true,
		},
		{
			name: "Ticket",
			selector: "column2", // Replace with the actual key from your data
			sortable: true,
		},
		{
			name: "Sent On",
			selector: "column3", // Replace with the actual key from your data
			sortable: true,
		},
		{
			name: "Sent From",
			selector: "column4", // Replace with the actual key from your data
			sortable: true,
		},
		{
			name: "Sent To",
			selector: "column5", // Replace with the actual key from your data
			sortable: true,
		},
		{
			name: "Status",
			selector: "column6", // Replace with the actual key from your data
			sortable: true,
		},
		{
			name: "Price",
			selector: "column7", // Replace with the actual key from your data
			sortable: true,
		},
	];

	useEffect(() => {
		const loadingTimeout = setTimeout(() => {
			setIsLoading(false);
		}, 2000);

		return () => clearTimeout(loadingTimeout);
	}, []);

	return (
		<>
			<div className="louter-container">
				<div
					className="lheading"
					style={{
						color: props.mode ? "white" : "black",
					}}>
					SMS Logs
				</div>
				<hr className="hr2" />
				<div className="ltop-section">
					<div className="ltopfield1">
						<label
							htmlFor=""
							className="filtertxt"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							Filter Logs By
						</label>
						<div className="filterdiv">
							<input
								className="subfilterdiv"
								type="text"
								value={text1}
								onFocus={handleFocus1}
								onChange={handleChange1}
							/>
							<input
								className="subfilterdiv"
								type="text"
								value={text2}
								onFocus={handleFocus2}
								onChange={handleChange2}
							/>
							<RangePicker
								className="custom-range-svg"
								style={{
									border: "2px solid gray",
									height: "50px",
									display: "flex",
									borderRadius: "10px",
									width: "280px",
								}}
							/>
							<div className="filtertxt">
								<button className="filtertxtbtn">Filter</button>
							</div>
						</div>
					</div>
				</div>

				<div className="logstablecontainer">
					<div className="logstable">
						<div
							className="logstableheading"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							Logs
						</div>
						{isLoading ? (
							<div className="loader-container">
								<BarLoader color={"#007bff"} loading={isLoading} width={200} />
							</div>
						) : (
							<DataTable
								// fixedHeader
								// fixedHeaderScrollHeight="500px"
								highlightOnHover
								pointerOnHover
								columns={columns}
								data={data}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
