import "./payasyougo.css";
import { Link } from "react-router-dom";
export default function Payasyougo(props) {
	return (
		<>
			<div id="upper">
				<Link to="/subscriptions">
					<div className="back-img">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="11"
							height="20"
							viewBox="0 0 11 20"
							fill="none">
							<path
								d="M9.48525 1.48486L1 9.99986L9.48525 18.5149"
								stroke={props.mode ? "white" : "black"}
								stroke-width="1.5"
								stroke-miterlimit="10"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
				</Link>
				<h1 className="pg" style={{ color: props.mode ? "white" : "" }}>
					Pay as You Go
				</h1>
			</div>

			<div id="box" style={{ background: props.mode ? "#2E2E48" : "" }}>
				<div className="wrappper">
					<div id="left">
						<div id="con1">
							<h1 className="hd" style={{ color: props.mode ? "white" : "" }}>
								Deposit
							</h1>
							<p className="pt" style={{ color: props.mode ? "white" : "" }}>
								1,2400 PKR
							</p>
						</div>
						<div id="con2">
							<h1 className="hd" style={{ color: props.mode ? "white" : "" }}>
								Free SMS
							</h1>
							<p className="pt" style={{ color: props.mode ? "white" : "" }}>
								1,000 SMS
							</p>
						</div>
						<div id="con3">
							<h1 className="hd" style={{ color: props.mode ? "white" : "" }}>
								Payment Terms
							</h1>
							<p className="pt" style={{ color: props.mode ? "white" : "" }}>
								15 Days
							</p>
						</div>
					</div>

					<div id="right">
						<div id="con4">
							<h1 className="hd" style={{ color: props.mode ? "white" : "" }}>
								Monthly Base Amount
							</h1>
							<p className="pt" style={{ color: props.mode ? "white" : "" }}>
								30,000 PKR
							</p>
						</div>
						<div id="con5">
							<h1 className="hd" style={{ color: props.mode ? "white" : "" }}>
								Per SMS Rate
							</h1>
							<p className="pt" style={{ color: props.mode ? "white" : "" }}>
								2.8 PKR
							</p>
						</div>
						<div id="con6">
							<h1 className="hd" style={{ color: props.mode ? "white" : "" }}>
								Payable
							</h1>
							<p className="pt" style={{ color: props.mode ? "white" : "" }}>
								130,000 PK
							</p>
						</div>
					</div>
				</div>
				<div id="button">
					<Link to="/checkout">
						<button className="btc">Next</button>
					</Link>
				</div>
			</div>
		</>
	);
}
