import React from "react";
import "./projectsettings.css";
import Editabletext from "../editabletext/editabletext";
import { PrimarySelect } from "../inputs/inputs";

export default function ProjectSettings(props) {
	const SPRT = {
		smstype: ["ibrahim@gmail.com", "muhammad@gmail.com", "uzair@gmail.com"],
	};
	return (
		<>
			<div
				className="ptitle"
				style={{
					color: props.mode ? "white" : "black",
				}}>
				Project Settings
			</div>
			<div
				className="pouter-container"
				style={{
					backgroundColor: props.mode ? "#2E2E48" : "white",
				}}>
				<div className="ptop-section">
					<div className="field1">
						<div
							className="text1"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							Project Name
						</div>
						<Editabletext initialText="NexCare" />
					</div>
					<div className="field2">
						<div
							className="text2"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							Project ID
						</div>
						<div
							className="subtext2"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							nexcare-cr7a1
						</div>
					</div>
					<div className="field3">
						<div
							className="text3"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							Project Number
						</div>
						<div
							className="subtext3"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							4411520542
						</div>
					</div>
					<div className="field4">
						<div
							className="text4"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							Web Api Key
						</div>
						<div
							className="subtext4"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							61520542
						</div>
					</div>
				</div>
				<hr className="hr" />
				<div className="pbottom-section">
					<div
						className="psubtitle"
						style={{
							color: props.mode ? "white" : "black",
						}}>
						Public Settings
					</div>
					<div className="botmfield1">
						<div
							className="botmtext1"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							Project Name
						</div>
						<Editabletext initialText="project-554956545" />
					</div>
					<div className="botmfield2">
						<div
							className="botmtext2"
							style={{
								color: props.mode ? "white" : "black",
							}}>
							Support Email
						</div>
						<PrimarySelect options={SPRT.smstype} />
					</div>
					<div>
						<button className="deleteprojectbtn" >Delete Project</button>
					</div>
				</div>
			</div>
		</>
	);
}
