import "../main.css";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../Theme";
import Sidebar from "../components/sidebar/sidebar";
import Navbar from "../components/navbar/navbar";
import ProjectSettings from "../components/projectsettings/projectsettings";
export default function Dashboard() {
	const { darkmode, toggleMode } = useContext(AppContext);

	return (
		<>
			<div className="sidebar-component">
				<Sidebar mode={darkmode} toggleMode={toggleMode} />
			</div>
			<div className="navbar-component">
				<Navbar mode={darkmode} username="Nexcare" />
			</div>
			<ProjectSettings mode={darkmode} toggleMode={toggleMode} />
		</>
	);
}
