import "./inputs.css";

import React from "react";
import { useContext } from "react";
import { AppContext } from "../../Theme";
function PrimarySelect(props) {
	const { darkmode } = useContext(AppContext);
	return (
		<div className="pr_select" id={props.id}>
			<p
				style={{
					marginBottom: "0rem",
					fontFamily: "Space Grotesk",
					fontWeight: "600",
					paddingLeft: "5px",
					color: darkmode ? "white" : "black",
				}}>
				{props.label}
			</p>
			<div className="p_sl">
				<select name={props.name} id={props.id} className="primary-select">
					{props.options.map((option, index) => {
						if (option) {
							return <option key={index}>{option}</option>;
						}
						return null;
					})}
				</select>
			</div>
		</div>
	);
}
function PrimaryInput(props) {
	const { darkmode } = useContext(AppContext);
	return (
		<div className="pr_input" id={props.id}>
			<p
				style={{
					marginBottom: "0rem",
					fontFamily: "Space Grotesk",
					fontWeight: "600",
					paddingLeft: "5px",
					color: darkmode ? "white" : "black",
					fontSize: props.fontSize,
				}}>
				{props.label}
			</p>
			<div className="p_sl">
				<input
					name={props.name}
					id={props.id}
					className="primary-select"
					type={props.type}
					placeholder={props.placeholder}
				/>
			</div>
		</div>
	);
}

function TextareaLarge(props) {
	const { darkmode } = useContext(AppContext);
	return (
		<>
			<div className="textarea" id={props.id}>
				<p className="lab" style={{ color: darkmode ? "white" : "black" }}>
					{props.label}{" "}
				</p>
			</div>
			<textarea
				className="txtarea"
				name=""
				id=""
				cols="70"
				rows="14"></textarea>
		</>
	);
}
export { PrimarySelect, PrimaryInput, TextareaLarge };
